import React, { useEffect, useRef } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "./House.scss";

export default function House() {
  const rightAnim = useRef(null);
  const imageRef = useRef(null);


  //   useEffect(() => {
  //     const handleAnimationIteration = () => {
  //         setTimeout(() => {
  //             document.querySelector(".homeImgs span img").style.opacity = "1";
  //         }, 3000);
  //         setTimeout(() => {
  //             document.querySelector(".homeImgs span img").style.opacity = "0";
  //         }, 7000);
  //     };

  //     const rightElement = rightAnim.current;
  //     rightElement.addEventListener('animationiteration', handleAnimationIteration);

  //     return () => {
  //         rightElement.removeEventListener('animationiteration', handleAnimationIteration);
  //     };
  // }, []);

  // useEffect(() => {
  //   const displayImage = () => {
  //       document.querySelector(".homeImgs span img").style.opacity = "1";
  //   };

  //   const hideImage = () => {
  //       document.querySelector(".homeImgs span img").style.opacity = "0";
  //   };

  //   const interval = setInterval(() => {
  //       displayImage();
  //       setTimeout(hideImage, 8000); // يخفي الصورة بعد 3 ثواني
  //   }, 6000); // يتم تشغيل الدالة كل 10 ثواني، وهو مدى دورة التأثير

  //   return () => {
  //       clearInterval(interval);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleAnimationIteration = () => {
  //     // عند بداية كل دورة جديدة من التأثير، نخفي الصورة
  //     document.querySelector(".homeImgs span img").style.opacity = "0";

  //     // نظهر الصورة بعد 3.5 ثواني من بداية التأثير
  //     setTimeout(() => {
  //       document.querySelector(".homeImgs span img").style.opacity = "1";
  //     }, 3500); // يظهر بعد 3.5 ثواني

  //     // نخفي الصورة بعد 6.5 ثواني من بداية التأثير
  //     setTimeout(() => {
  //       document.querySelector(".homeImgs span img").style.opacity = "0";
  //     }, 6500); // يختفي بعد 6.5 ثواني
  //   };

  //   const rightElement = rightAnim.current;
  //   rightElement.addEventListener("animationiteration", handleAnimationIteration);

  //   return () => {
  //     rightElement.removeEventListener("animationiteration", handleAnimationIteration);
  //   };
  // }, []);

  // useEffect(() => {
  //   const imgElement = document.querySelector(".homeImgs span img");
  //   if (imgElement) {
  //     imgElement.style.opacity = "1";
  //   }
  //   // عند تحميل المكون، نظهر الصورة بعد 3.5 ثواني
  //   setTimeout(() => {
  //     document.querySelector(".homeImgs span img").style.opacity = "1";
  //   }, 3500); // يظهر بعد 3.5 ثواني

  //   // ونخفي الصورة بعد 6.5 ثواني
  //   setTimeout(() => {
  //     document.querySelector(".homeImgs span img").style.opacity = "0";
  //   }, 6500); // يختفي بعد 6.5 ثواني

  //   const handleAnimationIteration = () => {
  //     // عند بداية كل دورة جديدة من التأثير، نخفي الصورة
  //     document.querySelector(".homeImgs span img").style.opacity = "0";

  //     // نظهر الصورة بعد 3.5 ثواني من بداية التأثير
  //     setTimeout(() => {
  //       document.querySelector(".homeImgs span img").style.opacity = "1";
  //     }, 3500);

  //     // نخفي الصورة بعد 6.5 ثواني من بداية التأثير
  //     setTimeout(() => {
  //       document.querySelector(".homeImgs span img").style.opacity = "0";
  //     }, 6500);
  //   };

  //   const rightElement = rightAnim.current;
  //   rightElement.addEventListener(
  //     "animationiteration",
  //     handleAnimationIteration
  //   );

  //   if (imageRef.current) {
  //     imageRef.current.style.opacity = "1";
  //   }

  //   return () => {
  //     rightElement.removeEventListener(
  //       "animationiteration",
  //       handleAnimationIteration
  //     );
  //   };
  // }, []);


  useEffect(() => {
    const handleImageVisibility = (opacity) => {
      if (imageRef.current) {
        imageRef.current.style.opacity = opacity;
      }
    };

    const handleAnimationIteration = () => {
      handleImageVisibility("0");

      setTimeout(() => handleImageVisibility("1"), 3500);
      setTimeout(() => handleImageVisibility("0"), 6500);
    };

    const rightElement = rightAnim.current;
    rightElement.addEventListener("animationiteration", handleAnimationIteration);

    setTimeout(() => handleImageVisibility("1"), 3500);
    setTimeout(() => handleImageVisibility("0"), 6500);

    return () => {
      rightElement.removeEventListener("animationiteration", handleAnimationIteration);
    };
  }, []);

  
  return (
    <div className="homeHeader">
      <div className="container0 homeContainer">
        <section className="home">
          <h5 className="animationLeft">YOU DREAM</h5>
          <div className="homeImgs">
            <h6 className="animationRight" ref={rightAnim}>
              <img
                // src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/WE DESIGN.svg"
                src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/WE DESIGN.png"
                alt="WE DESIGN"
              />
            </h6>

            <span>
              <img
                ref={imageRef}
                src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Your Reality.svg"
                alt="Your Reality"
              />
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}
