import React, { useEffect } from "react";
import ProjectsHead from "./ProjectsHead/ProjectsHead";
import { Helmet } from "react-helmet";
import ProjectsMain from "./ProjectsMain/ProjectsMain";

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Projects" />
      </Helmet>
      <ProjectsHead />
      
      {/* <ProjectsMain /> */}
      <ProjectsMain />
    </>
  );
}
