import React from "react";
import "./../ItemDetails.scss";
import { useParams } from "react-router-dom";
// import { useEffect } from "react";
// import axios from "axios";
// import Slider from "react-slick";
// import Loading from "../../../Loading/Loading";
import { useState, useEffect } from "react";
// import ServicesHead from "./../ServicesHead/ServicesHead";
// import Item from "./Item";
import Data from "./Data";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
// import imgDe from "./../../../../assets/Rectangle 88.png";

// import img from "./../../../../assets/home/home.png";

export default function ItemDetails() {
  const [item, setItem] = useState(null);
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const itemId = parseInt(params.detailsId, 10);
    const selectedItem = Data.find((item) => item.id === itemId);
    setItem(selectedItem);
  }, [params.detailsId]);

  if (!item) {
    return <div>Loading...</div>;
  }

  const imageKeys = Object.keys(item).filter((key) =>
    key.startsWith("imgDetails")
  );

  console.log('==========item==========================');
  console.log(item.imgDesktop);
  console.log('====================================');

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 700,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   autoplay: true,
  // }; 
  return (
    <section className="containerItemDetails">
      <div
        className="itemDetailsHeader"
        style={{
          // backgroundImage: `url(${item.backgroundImage})`,
          backgroundImage: `url(${window.innerWidth < 768 ? item.imgMobile : item.imgDesktop})`,
        }}
      >
        <div className="container textServicesDetails">
          <h2>our services</h2>
          <h3>{item.title1}</h3>
        </div>
      </div>

      <div className="container">
        <div className="itemDetails">
          {/* <h2>steel structure </h2> */}
          <h2>{item.title}</h2>
          <p className="descriptionMain">{item.description}</p>
          <p className="descriptionMain">{item.description2}</p>
          <p className="descriptionMain">{item.description3}</p>
          <p className="descriptionMain">{item.description4}</p>

          <div className="contDetails">
            <div className="imgsTop">
              {/* <img className="imgDetails1" src={item.imgDetails1} alt="" /> */}
              {/* <div className="ourServSlid1"> */}
              {/* <div className="my-5 container1">
                <Slider {...settings} autoplayspeed={3000} className="">
                  {Data.length > 0 ? (
                    Data.map((item) => {
                      return (
                        <div key={item.id} className="itemDetailsSlider">
                          <img src={item.img} alt="servicesImg" className="" />
                        </div>
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </Slider>
              </div> */}
              {/* </div> */}

              {/* <div className="imgsRight"> */}
              {/* <img className="" src={item.imgDetails5} alt="" /> */}
              {/* <img className="imgDetails2" src={item.imgDetails2} alt="" /> */}
              {/* </div> */}
            </div>

            <div className="imgsButtom">
              {/* <>
                <img
                  className="imgDetails2"
                  src={item.imgDetails1}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails2"
                  src={item.imgDetails2}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails3"
                  src={item.imgDetails3}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={item.imgDetails4}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={item.imgDetails5}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={item.imgDetails6}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={item.imgDetails7}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={item.imgDetails8}
                  alt="servicesImg"
                />
              </> */}

              {/* {imageKeys.map((imgKey, index) => {
                if (item[imgKey]) {
                  return (
                    <img
                      key={index}
                      className={`imgDetails${index + 1}`}
                      src={item[imgKey]}
                      alt="servicesImg"
                    />
                  );
                }
                return null;
              })} */}

              {/* <ImageGallery
                items={imageKeys.map((imgKey, index) => {
                  if (item[imgKey]) {
                    return (
                      <img
                        key={index}
                        className={`imgDetails${index + 1}`}
                        src={item[imgKey]}
                        alt="servicesImg"
                      />
                    );
                  }
                  return null;
                })}
              /> */}

              <ImageGallery
                // stopPropagation={true}
                // disableSwipe={false}
                // disableThumbnailSwipe={false}
                showIndex={true}
                showBullets={true}
                autoPlay={true}
                items={imageKeys
                  .map((imgKey,index) => {
                    if (item[imgKey]) {
                      return {
                        original: item[imgKey],
                        thumbnail: item[imgKey],
                        key: `image_${index}`,
                      };
                    }
                    return null;
                  })
                  .filter(Boolean)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
