import "./OurClients.scss";
// import img from "./../.../../../../assets/Logo 2.png";
// import Loading from "../../Loading/Loading";
import Data from "./Data";
import Item from "./Item";
import DataEngineering from "./DataEngineering";
import { useEffect } from "react";

export default function OurDevelopers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Data2 = Data.map((item) => {
    return (
      <>
        <Item
          key={item.id}
          id={item.id}
          title={item.title}
          img={item.img}
          imgHover={item.imgHover}
          // imgDetails1={item.imgDetails1}
          // imgDetails2={item.imgDetails2}
          // imgDetails3={item.imgDetails3}
          // imgDetails4={item.imgDetails4}
          // imgDetails5={item.imgDetails5}
        />
      </>
    );
  });

  const DataEng = DataEngineering.map((item) => {
    return (
      <>
        <Item
          key={item.id}
          id={item.id}
          title={item.title}
          img={item.img}
          imgHover={item.imgHover}
          // imgDetails1={item.imgDetails1}
          // imgDetails2={item.imgDetails2}
          // imgDetails3={item.imgDetails3}
          // imgDetails4={item.imgDetails4}
          // imgDetails5={item.imgDetails5}
        />
      </>
    );
  });

  return (
    <section className="ourDevelopers">
      <div className="container">
        <div className="ourDeveHead">
          <h2>OUR CLIENTS</h2>
          {/* <p>The most premium real estate developers in Egypt</p> */}
        </div>

        {/* <div className="ourDeveContent"> */}
        {/* <div className="cardsClients"> */}
        <div className="cardsClients cardsTop">{Data2}</div>
        {/* </div> */}

        {/* <div className="cardsMain">
            <div className="cards"> */}
        <div className="ourDeveHead">
          <h2>OUR Partner</h2>
          {/* <p>The most premium real estate developers in Egypt</p> */}
        </div>
        <div className="cardsPartner cardsClients">{DataEng}</div>
        {/* </div>
          </div> */}
        {/* </div> */}
      </div>
    </section>
  );
}
