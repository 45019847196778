import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
// import { baseUrl } from "./../../../utils/baseUrl";
import Loading from "../../Loading/Loading";

// import star from "./../../../assets/home/Star 5.svg";
// import imgBeforeText from "./../../../assets/home/Comma.png";
// import imgAfterText from "./../../../assets/home/Comma2.png";
import "./Testimonials.scss";

export default function Testimonials() {
  const baseUrl = "https://dashboard.laurentegypt.com/api";
  const [categories, setCategories] = useState([]);

  
  const getAllCategories = async () => {
    try {
      let { data } = await axios.get(`${baseUrl}/testimonial`);
      // console.log(data.data);
      // setCategories(data.data);
      if (data && data.data !== null) {
        setCategories(data.data);
    } else {
        console.log("API returned null for categories");
    }
    } catch (error) {
      console.log("Error", error);
      setCategories([]);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };
  return (
    <div className="testimonials">
      <div className="container">
        <section className="testimonialsContent">
          <div className="testimonialsHeader">
            <div className="stars">
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Star 5.svg"
                alt="star"
              />
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Star 5.svg"
                alt="star"
              />
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Star 5.svg"
                alt="star"
              />
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Star 5.svg"
                alt="star"
              />
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Star 5.svg"
                alt="star"
              />
            </div>

            <h3>Testimonials</h3>
          </div>

          <div className="testimonialsSlider">
            <div className="my-5 container">
              <Slider {...settings} autoplayspeed={3000} className="">
                {categories.length > 0 ? (
                  categories.map((item) => {
                    return (
                      <div key={item.id} className="parentSlider">
                        <div className="imgSlider">
                          <img
                            src={item.photo}
                            alt="testimonialsSliderImg"
                            className=""
                          />
                        </div>
                        <div className="sliderText">
                          <div className="imgBeforeText">
                            <img
                              src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Comma.png"
                              alt="testimonialsIcon"
                            />
                            <img
                              src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Comma.png"
                              alt="testimonialsIcon"
                            />
                          </div>
                          <p>
                            {item.descr}
                          </p>
                          <div className="imgAfterText">
                            <img
                              src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Comma2.png"
                              alt="testimonialsIcon"
                            />
                            <img
                              src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurenthome/Comma2.png"
                              alt="testimonialsIcon"
                            />
                          </div>
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Loading />
                )}
              </Slider>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
