import React, { useEffect } from "react";
// import './Item.scss'
import { Link } from "react-router-dom";

export default function Item(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <section className="box">
      <div className="itemContent">
        <Link to={`/details/${props.id}`}>
          <div className="item">
            <img className="" src={props.img} alt="imgCard" />
            {/* <div className="overlay d-flex align-items-center text-center">
              <p>{product.description.split(" ").slice(0, 10).join(" ")}</p>
            </div> */}
            {/* </Link> */}
          </div>

          <div className="text">
            <div>
              <h5>
                {props.title}
                {/* {product.title.split(" ").slice(0, 5).join(" ")} */}
              </h5>
              {/* <h6>20 PROJECTS</h6> */}
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
}
