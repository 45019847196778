import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import Footer from "./../Footer/Footer";
// import { Container } from "react-bootstrap";
// import { Offline, Online } from "react-detect-offline";

export default function MainLayout() {
  return (
    <>
      <Navigation />

      {/* <Container> */}
      <Outlet></Outlet>
      {/* </Container> */}

      {/* <Online>
        {" "}
        <div className="network">Only shown when you're online</div>{" "}
      </Online> */}
      {/* <Offline>
        <div className="network">Only shown offline (surprise!) <i className="fas fa-wifi"></i> </div>
      </Offline> */}
      <>
      {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
      </>
      <Footer />
    </>
  );
}
