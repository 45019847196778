import React, { useEffect } from "react";
import "./WhoWeAre.scss";
// import img from "./../../../assets/about/Layer_1.svg";

export default function WhoWeAre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="whoWeAreHeader">
        <h2>WHO WE ARE</h2>
        {/* <h5>we are the builders </h5> */}
      </div>
      <section className="whoWeAre">
        <div className="whoWeAreLeft">
          <p>
            <span>Laurent Construction and Engineering Services </span>
            is what you need to reach new heights. In every project, we are
            committed to excellence, effectiveness, functionality, efficiency,
            and aesthetics, taking our client’s requirements into consideration,
            embracing challenges, and striving to earn our clients' trust.
            <br />
            <br />
            <span>
              We have successfully completed several construction projects
            </span>
            from start to finish, tailoring each project to meet the needs of
            our clients.
            <br />
            <br />
          </p>
        </div>
        <div className="whoWeAreRight">
          <img
            src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/logo.png"
            alt="aboutLogo"
          />
        </div>
      </section>

      <section className="oursCards">
        <div className="cardLeft">
          <h2>OUR MISSION</h2>
          <p>Creating exceptional spaces that inspire and enrich lives.</p>
        </div>
        <div className="cardRight">
          <h2>OUR vision</h2>
          <p>
            Leading force in construction, shaping the built environment with
            excellence and innovation.
          </p>
        </div>
      </section>
    </div>
  );
}
