import React,{useEffect} from "react";
import AboutUs from "./AboutUs/AboutUs";
import OurProjects from "./OurProjects/OurProjects";
// import WeGrow from "./WeGrow/WeGrow";
import OurServices from "./OurServices/OurServices";
// import LestMake from "./LestMake/LestMake";
import { Helmet } from "react-helmet";
import House from "./House/House";
import Header from "./Header/Header";
import Testimonials from "./Testimonials/Testimonials";

export default function Home() {
  useEffect(() => {
    window.scrollTo(1, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home" />
      </Helmet>
      <Header />
      <AboutUs />
      <House />
      <OurServices />
      <OurProjects />
      <Testimonials />

      {/* <WeGrow /> */}
      {/* <LestMake /> */}
    </>
  );
}
