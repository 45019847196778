import { useEffect } from "react";
import "./ServicesHead.scss";

export default function ServicesHead() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="servicesHead">
      <div className="container">
        {/* <h1>our services</h1>
        <h6>
          Laurent provides a wide range of services to meet all your
          construction and engineering needs. From initial conceptualization and
          project planning to execution and final delivery.
        </h6> */}
      </div>
    </section>
  );
}
