import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AboutUs.scss";

export default function AboutUs() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isExpanded, setIsExpanded] = useState(!isMobile); 

  const toggleText = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      const mobileCheck = window.innerWidth <= 768;
      setIsMobile(mobileCheck);
      setIsExpanded(!mobileCheck);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="about_us">
        <div className="container">
          <div className="homeHeaderContent">
            {/* <i class="fa-duotone fa-house"></i>
        <FontAwesomeIcon icon={faCoffee} /> */}

            <h2>About Us</h2>
            {/* <h3>construction</h3> */}
            <p>
              Laurent Construction and Engineering is a reputable engineering
              services firm committed to delivering premium results for your
              project. Our full-service construction company is customized to
              meet your specific needs, offering end-to-end construction and
              industrial engineering solutions.
              <br />
              <br />
              {isExpanded && (
                <>
                  Our team of experts, with extensive experience in the
                  industry, will manage your project from beginning to end,
                  covering all stages. As one of the leading engineering
                  companies in Egypt, we pride ourselves on exceeding your
                  expectations while adhering to the highest international
                  standards by leveraging innovation and utilizing top-notch
                  technologies.
                  <br />
                  <br />
                  Allow us the opportunity to showcase our dedication to
                  delivering exceptional construction solutions with excellence.
                  <br />
                  <br />
                  <span className="btnReadMore">
                    <Link to="/about">read more</Link>
                    {/* <button>
              
            </button> */}
                    {/* <img src={img2} alt="" /> */}
                  </span>
                </>
              )}
            </p>

            {isMobile && !isExpanded && (
              <div className="btnReadMoreText">
                <button onClick={toggleText}>More</button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
