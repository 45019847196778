import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navigation.scss";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  const [navExpanded, setNavExpanded] = useState(false);

  const closeNav = () => {
    setNavExpanded(false);
  };

  return (
    <section className="navigation">
      <Navbar
        expand="lg"
        className="bg-body-tertiary1 fixed-top1 "
        expanded={navExpanded}
      >
        <Container>
          <div className="navLogo">
            <NavLink to="/" onClick={closeNav}>
              <img
                className="navLogoDik"
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/home/navLogo.svg"
                alt="navLogo"
              />
            </NavLink>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="iconMenu"
            onClick={() => setNavExpanded((prev) => !prev)}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto  align-items-center">
              <NavLink to="/" onClick={closeNav}>
                Home
              </NavLink>
              <NavLink to="/about" onClick={closeNav}>
                About
              </NavLink>
              <NavLink to="/services" onClick={closeNav}>
                Services
              </NavLink>
              <NavLink to="/projects" onClick={closeNav}>
                Projects
              </NavLink>
              <NavLink to="/contact" onClick={closeNav}>
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}
