// import img1 from "./../../../assets/services/engineering/Rectanagle 852.png";
// import img2 from "./../../../assets/services/engineering/Rectangle f852.png";
// import img3 from "./../../../assets/services/engineering/Rectangle2.png";
// import img4 from "./../../../assets/services/engineering/Rectangle v852.png";
// import img5 from "./../../../assets/services/engineering/Rectangle g852.png";
// import img6 from "./../../../assets/services/engineering/Rectangle h852.png";
// import imgDetails1 from "./../../../assets/services/engineering/engineeringDetails/Mask.png";
// import imgDetails2 from "./../../../assets/services/engineering/engineeringDetails/Mask3.png";
// import imgDetails3 from "./../../../assets/services/engineering/engineeringDetails/Mask1.png";
// import imgDetails4 from "./../../../assets/services/engineering/engineeringDetails/Mas2k.png";
// import imgDetails5 from "./../../../assets/services/engineering/engineeringDetails/Mask5.png";

// import img5 from "./../../../assets/Artboard 6 1.png";
// import img6 from "./../../../assets/Artboard 8 1.svg";

// import mgDetails1 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 11125.png";
// import mgDetails2 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Recdtangle 126.png";
// import mgDetails3 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangledd 123.png";
// import mgDetails4 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangleaa 125.png";
// import mgDetails5 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 1ff26.png";
// import mgDetails6 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 12gg.png";
// import mgDetails7 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle ff125.png";
// import mgDetails8 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 12dd6.png";
// import mgDetails9 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangldde 123.png";

// import mgDetails10 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangleee 125.png";
// import mgDetails11 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 17726.png";
// import mgDetails12 from "./../../../../assets/services/mep/mepDetails/mechanical/interior/Rectangle 12223.png";

// import imgDetails6 from "./../../../assets/services/mep/mepDetails/mechanical/Rectangle d126.png";
// import imgDetails7 from "./../../../assets/services/mep/mepDetails/mechanical/Rectangle f123.png";

// import imgDetails8 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectan25.png";
// import imgDetails9 from "./../../../../assets/services/mep/mepDetails/mechanical/Recta26.png";
// import imgDetails10 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectan23.png";
// import imgDetails11 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1qq25.png";
// import imgDetails12 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectassngle 126.png";
// import imgDetails13 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1ff23.png";

// import imgDetails14 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle ff125.png";
// import imgDetails15 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 12dd6.png";
// import imgDetails16 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangldde 123.png";
// import imgDetails17 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangleee 125.png";
// import imgDetails18 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 17726.png";
// import imgDetails19 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 12223.png";

// import imgDetails20 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangled 125.png";
// import imgDetails21 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectanglيe 126.png";
// import imgDetails22 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl123.png";
// import imgDetails23 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl1e 125.png";
// import imgDetails24 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1226.png";
// import imgDetails25 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle2 123.png";

// import imgDetails014 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangele 125.png";
// import imgDetails015 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangl5e 126.png";
// import imgDetails016 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1ث23.png";
// import imgDetails017 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectangle 1425.png";
// import imgDetails018 from "./../../../../assets/services/mep/mepDetails/mechanical/Rectanglي126.png";

//bg img

// import backgroundImage4 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-2.jpg";
// import backgroundImage5 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-3.jpg";
// import backgroundImage6 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-4.jpg";
// import backgroundImage7 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-6.jpg";
// import backgroundImage8 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-5.jpg";
// import backgroundImage9 from "./../../../../assets/services/HERO-SECTION-WEB/Group19189-7.jpg";

// import imgMobile4 from "./../../../../assets/services/HERO-SECTION-MOBILE/STEELHEROmobile.png";
// import imgMobile5 from "./../../../../assets/services/HERO-SECTION-MOBILE/Interior-exterior-HEROmobile.png";
// import imgMobile6 from "./../../../../assets/services/HERO-SECTION-MOBILE/BILL-HEROmobile.png";
// import imgMobile7 from "./../../../../assets/services/HERO-SECTION-MOBILE/MEP-COMMIREAL-HEROmobile.png";
// import imgMobile8 from "./../../../../assets/services/HERO-SECTION-MOBILE/Residential-HEROmobile.png";
// import imgMobile9 from "./../../../../assets/services/HERO-SECTION-MOBILE/FoundationHEROmobile.png";

const DataEngineering = [
  {
    id: 1,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19195.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle5446.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle5446.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectanglee 126.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangled 123.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangvle 125.svg",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1y26.png",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangleg 123.png",

    imgDetails11:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 126.jpg",
    imgDetails10:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 126-1.jpg",

    imgDetails9:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 125.jpg",

    imgDetails5:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 126-1.jpg",

    imgDetails7:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 123-1.jpg",

    imgDetails4:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 127.jpg",

    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 128.jpg",

    imgDetails8:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 129.jpg",

    imgDetails6:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Rectangle 130.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/SteelStructure/WhatsApp Image 2023-07-18 at 1.49.36 PM copy.jpg",

    // imgDetails12:'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/WhatsApp Image 2023-07-18 at 1.49.48 PM (1).jpeg',
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/SteelStructure/WhatsApp Image 2022-10-06 at 3.42.16 PM copy.jpg",

    // imgDetails6: imgDetails6,
    // imgDetails7: imgDetails14,
    title: "Steel structure",
    description:
      "Our steel buildings have the following advantages: wide span, high strength, lightweight, temperature protection, energy savings, attractive appearance, and fast construction time. ",
    description02:
      "The steel buildings are also preferred for their strong insulating effect, long lifespan, space efficiency, good seismic performance, and flexible layout, in addition to their low cost We are completely confident in the quality of our steel structure buildings.",
    // description03:'',
    // link:'https://www.google.com/'
  },
  {
    id: 2,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19196.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Group-19189.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Group-19189.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 11125.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Recdtangle 126.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangledd 123.png",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangleaa 125.png",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1ff26.png",
    // imgDetails6:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 12gg.png",

    // imgDetails7:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle ff125.png",
    // imgDetails8:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 12dd6.png",
    // imgDetails9:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangldde 123.png",
    // imgDetails10:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangleee 125.png",
    // imgDetails11:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 17726.png",
    // imgDetails12:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 12223.png",

    imgDetails9:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 123 (1).jpg",

    imgDetails8:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 123 (2).jpg",
    imgDetails10:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 123 (3).jpg",

    imgDetails7:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 123.jpg",

    imgDetails11:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 125 (1).jpg",
    imgDetails12:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 125 (2).jpg",
    imgDetails6:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 125 (3).jpg",

    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 125.jpg",

    imgDetails5:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 126 (1).jpg",

    imgDetails3:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 126 (2).jpg",

    imgDetails1:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 126 (3).jpg",

    imgDetails4:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/InteriorExteriorDesign/Rectangle 126.jpg",

    title: "interior and Exterior Design",
    description:
      "Our dedicated and innovative Designers strive to deliver high-quality and sustainable solutions for our Clients across a wide range of industries.  ",
    description02:
      "Laurent's interior design services include complete makeovers and design overhauls, providing opportunities for customization. ",
    description03:
      " Exterior design remodeling services range from installing exterior shutters to full-scale exterior remodels.",
    description04:
      "Laurent is one of the best interior design when it comes to cost-effective and time-efficient solutions.",
    // link:'https://www.youtube.com/'
  },
  {
    id: 3,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19197.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle5446.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle5446.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectk25.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectaj6.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangll23.png",

    imgDetails1:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle 123.jpg",
    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle 125-1.jpg",
    imgDetails3:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle 125.jpg",
    imgDetails4:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle 126-1.jpg",
    imgDetails5:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Bills/Rectangle 126.jpg",
    // imgDetails6:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Billsofquantitiesservice /2 copy.jpg",
    // imgDetails7:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Billsofquantitiesservice /1 copy.jpg",

    // imgDetails4: imgDetails11,
    // imgDetails5: imgDetails12,
    // imgDetails6: imgDetails13,
    // imgDetails7: imgDetails14,
    title: "Bills of quantities (BOQ)",
    description:
      "Our expert engineers at Laurent for construction prepare Bills of quantities divided into sections that explain the project, materials needed, unit prices, provisional sums, methods of measurement, and potential errors.",
    description02:
      "Our team allows construction companies to create the estimated cost of the project and allows them to bid on taking the work.",
    description03: "",
    // link:''
  },
  {
    id: 4,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19198.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle5446.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle5446.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangele 125.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangl5e 126.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle treh.png",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1425.png",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectanglي126.png",

    imgDetails10:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle 123.jpg",

    imgDetails6:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle 125-1.jpg",

    imgDetails1:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle 125.jpg",
    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle 126-1.jpg",

    imgDetails11:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/CommercialConstruction/Rectangle 126.jpg",

    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/WhatsApp Image 2023-08-13 at 14.57.32POJP copy.jpg",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/WhatsApp Image 2023-08-13 at 14.57.32OIOO copy.jpg",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/WhatsApp Image 2023-08-13 at 14.57.32OIJHPO copy.jpg",

    // imgDetails7:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/sr padel9790 copy.jpg",
    // imgDetails8:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/sr padel9788 copy.jpg",
    // imgDetails9:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServicesCommercialConstruction/sr padel9744 copy.jpg",

    // imgDetails6: imgDetails13,
    // imgDetails7: imgDetails14,
    title: "Commercial Construction",
    description:
      "A focused management system that oversees all areas of every project, from planning, budgeting, and preconstruction to designing, building, and completing, as well as regulating costs and lowering the possibility of setbacks and project delays.  ",
    description02:
      "Our commercial and industrial engineers have developed and constructed commercial buildings, corporate campuses, medical centers, sports complexes, restaurants, and food service facilities.",
    // description03:'',
    // link:''
  },
  {
    id: 5,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19199.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Group19189.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Group19189.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangled 125.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectanglيe 126.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectanglيe 126.png",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangl1e 125.png",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1226.png",
    // imgDetails6:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle2 123.png",

    imgDetails6:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 123-1.jpg",
    imgDetails3:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 125-1.jpg",

    imgDetails7:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 125.jpg",
    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 126-1.jpg",

    imgDetails1:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 126.jpg",

    imgDetails4:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/residentialConstruction/Rectangle 123.svg",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/ResidentialConstruction/cityscape-with-high-houses copy.jpg",

    // imgDetails7: imgDetails14,
    title: "residential Construction",
    description:
      "Our end-to-end competence in power solutions for the Residential industry makes us a preferred provider, from precisely assessing a site and providing a solution to installing and delivering. ",
    description02:
      "Laurent provides hassle-free, budget-friendly services with high quality and durability.",
    // description03:'',
    // link:''
  },
  {
    id: 6,
    img: "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19200.jpg",
    imgDesktop:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle5446.jpg",
    imgMobile:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle5446.jpg",

    // imgDetails1:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectan25.png",
    // imgDetails2:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Recta26.png",
    // imgDetails3:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectan23.png",
    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1qq25.png",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectassngle 126.png",
    // imgDetails6:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurentservices/laurentservicesdataEngineering/Rectangle 1ff23.png",
    imgDetails2:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 123-1.jpg",

    imgDetails1:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 123.jpg",

    imgDetails3:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 125-1.jpg",
    imgDetails7:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 125.jpg",

    imgDetails8:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 126-1.jpg",
    imgDetails6:
      "https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/services/Foundation/Rectangle 126.jpg",

    // imgDetails4:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Foundationworks/WhatsApp Image 2023-07-18 at 1.45.00 PM copy.jpg",
    // imgDetails5:
    //   "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/publicWebsiteServices/Foundationworks/48511ea5-14bd-4826-b682-feddd37eda40 copy.jpg",

    // imgDetails7: imgDetails14,
    title: "Foundation works",
    description:
      "As experts in the construction and civil engineering industry, We develop different types of foundations: ",
    description2: "Slab-on-grade with stem wall.",
    description3: "Alternative methods.",
    description4: "Monolithic slab",
    description5: "Piles and piers",
    description6: "Crawlspace.",
    description7: "Basement",
    // link:''
  },
];
export default DataEngineering;
