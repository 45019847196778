import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
// import { baseUrl } from "../../../utils/baseUrl";
import Loading from "./../../Loading/Loading";
import "./OurProjects.scss";
export default function OurProjects() {
  // const [isTyping, setIsTyping] = useState(false);
  // let text = <span>recant</span>;
  // var text = 'recant';
  const [categories, setCategories] = useState([]);

  const baseUrl = "https://dashboard.laurentegypt.com/api";

  const getAllCategories = async () => {
    try {
      let { data } = await axios.get(`${baseUrl}/portfolio/home`);
      // console.log(data.data);
      // setCategories(data.data);
      setCategories(Array.isArray(data.data) ? data.data : []);
      if (Array.isArray(data.data)) {
        setCategories(data.data);
      } else {
        // console.error("Received non-array data:", data.data);
        setCategories([]);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    // infinite: 1,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    autoplay: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 1400, settings: { slidesToShow: 2 } },
    ],
    // responsive: [{ breakpoint: 1400, settings: { slidesToShow: 2 } }],
  };

  return (
    <section className="ourProjects">
      <div className="container">
        <div className="headOurPro">
          <h3 className="textDec">our projects</h3>

          {/* <h3 className="textMop">our projects</h3> */}

          {/* <h3>
            our <span>recent</span> projects
          </h3> */}
          <h4>
            This is a glimpse of our latest accomplishments in construction and
            engineering.
          </h4>
          {/* <hr /> */}
        </div>
      </div>

      <div className="slider">
        <div className="homeContent1">
          <div className="my-5 container">
            <Slider {...settings} autoplayspeed={3000} className="">
              {Array.isArray(categories) && categories.length > 0 ? (
                categories.map((item, index) => {
                  return (
                    <div key={index} className="parentImg ">
                      <div className="imgSlide">
                        <img
                          src={item.home_page_thumbnail}
                          alt="ourProjectsImgSlider"
                          className=""
                          // height={200}
                        />
                      </div>
                      <div className="slidText">
                        <div>
                          <h5>{item.project_name}</h5>
                          <h6>{item.location}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Loading />
              )}
            </Slider>

            {/* <Slider {...settings} autoplayspeed={3000} className="">
  {categories.length > 0 ? (
    categories.map((item) => (
      <div key={item.id} className="parentImg">
        <div className="imgSlide">
          <img
            src={item.home_page_thumbnail}
            alt="ourProjectsImgSlider"
            className=""
          />
        </div>
        <div className="slidText">
          <div>
            <h5>{item.project_name}</h5>
            <h6>{item.location}</h6>
          </div>
        </div>
      </div>
    ))
  ) : (
    <Loading />
  )}
</Slider> */}
          </div>
        </div>
      </div>
    </section>
  );
}
