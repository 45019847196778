import React from "react";
import ReactDOM from "react-dom/client";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import AOS from "aos";
import "./index.scss";

// AOS.init({
//   duration: 2800,
//   easing: "ease-in-out",
//   delay: 300,
//   once: false,
//   mirror: true,
  
// });

// AOS.refresh();
// window.addEventListener('resize', () => {
// });

// useEffect(() => {
//   AOS.init(); // تهيئة مكتبة AOS عند تحميل المكون

//   // قم بإعادة تشغيل الانميشن بعد انتهاء التحديث (عندما يتم تغيير الحالة)
//   AOS.refresh();
// }, []);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
