// import { useState, useEffect } from "react";
import { useEffect } from "react";
import DataEngineering from "./EngineeringServices/DataEngineering";
import Item from "./MepServices/Item";
import ItemEng from "./EngineeringServices/ItemEng";
import Data from "./MepServices/Data";
import "./ServicesMian.scss";
// import ItemDetails from "./MepServices/ItemDetails";

export default function ServicesMian() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Data2 = Data.map((item) => {
    return (
      <>
        <Item
          key={item.id}
          id={item.id}
          title={item.title}
          img={item.img}
          // backgroundImage={item.backgroundImage}
          // imgDetails1={item.imgDetails1}
          // imgDetails2={item.imgDetails2}
          // imgDetails3={item.imgDetails3}
          // imgDetails4={item.imgDetails4}
          // imgDetails5={item.imgDetails5}
        />
      </>
    );
  });

  const DataEng = DataEngineering.map((item) => {
    return (
      <>
        <ItemEng
          key={item.id}
          id={item.id}
          title={item.title}
          img={item.img}
          // imgDetails1={item.imgDetails1}
          // imgDetails2={item.imgDetails2}
          // imgDetails3={item.imgDetails3}
          // imgDetails4={item.imgDetails4}
          // imgDetails5={item.imgDetails5}
        />
      </>
    );
  });

  return (
    <div className="container">
      <section className="services">
        <div className="servHead">
          <h2>MEP Services</h2>
          <h3>Mechanical, Electrical, And Plumbing</h3>
          <p>
            MEP, stands for Mechanical, Electrical, and Plumbing, is one
            of the most important services provided at Laurent. Our team of
            experts ensures that all aspects of our MEP services meet strict
            standards.
          </p>
        </div>

        <div className="servCards">
          <div className="boxes">{Data2}</div>
        </div>

        <div className="servHead py-5 mt-5">
          <h2>engineering Services</h2>
          <p>
            Laurent provides a wide range of services to meet all your
            construction and engineering needs. From initial conceptualization
            and project planning to execution and final delivery.
          </p>
        </div>

        <div className="servCards">
          <div className="boxes">{DataEng}</div>

          {/* <div className="boxes">
            {products.length > 0 ? (
              products.map((product) => {
                return (
                  <div className="box" key={product.id}>
                    <Item product={product} />
                  </div>
                );
              })
            ) : (
              <Loading />
            )}
          </div> */}
        </div>
      </section>
    </div>
  );
}
