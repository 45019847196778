import React, { useEffect } from 'react'
import './AboutHead.scss'

export default function AboutHead() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className='about'>
      
    </section>
  )
}
