// import img1 from "./../../../assets/Background.png";
// import img1 from "./../../../assets/home/our services/Rectanglfe 852.png";
// import img2 from "./../../../assets/home/our services/Rectacngle 852.png";
// import img3 from "./../../../assets/home/our services/Rectangleds 852.png";
// import img4 from "./../../../assets/home/our services/Rectaxngle 852.png";
// import img5 from "./../../../assets/home/our services/Rectangle 8d52.png";
// import img6 from "./../../../assets/home/our services/cf852.png";
// import img7 from "./../../../assets/home/our services/Rectanglae 852.png";
 
const Data = [
  {
    id: 1,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852.png',
    // number: "01",
    title: "MEP Services",
    // title2: "Plumbing engineering services",
    // title3: "include:",
    class:"firstCard",
    description: "1.Mechanical Engineering",
    description2: "Our HVAC Engineering service provides mechanical designs that include Heating, Ventilation, and Air Conditioning (HVAC) systems.",

    description3: "2.Electrical Engineering",
    description4: "In addition to providing lightning protection devices and fire alarm systems, Laurent Electrical Engineering also offers Integrated electrical system design.",

    description5: "3.Plumbing Engineering",
    description6: "Laurent provides Sustainable Plumbing solutions. Our plumbing experts design reliable systems at any scale.",
  },
  {
    id: 2,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852-1.png',
    // number: "02",
    title: "Steel structure",
    description:
      "Our steel buildings have the following advantages: wide span, high strength, lightweight, temperature protection, energy savings, attractive appearance, and fast construction time.",
    description2:
      "The steel buildings are also preferred for their strong insulating effect, long lifespan, space efficiency, good seismic performance, and flexible layout, in addition to their low cost.  ",
    description3:
      "We are completely confident in the quality of our steel structure.",
  },
  {
    id: 3,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852-2.png',
    // number: "03",
    title: "Commercial Construction",
    description:
      "A focused management system that oversees all areas of every project, from planning, budgeting, and preconstruction to designing, building, and completing, as well as regulating costs and lowering the possibility of setbacks and project delays.",
    description2:
      "Our commercial and industrial engineers have developed and constructed commercial buildings, corporate campuses, medical centers, sports complexes, restaurants, and food service facilities",
      
    // description3:
    // "Our residential contractors take a proactive approach to your project to maximize lifetime cost savings and keep the project on schedule.",
  },
  {
    id: 4,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852-3.png',
    // number: "04",
    title: "Residential construction ",
    title2:
      "Our end-to-end competence in power solutions for the Residential industry makes us a preferred provider, from precisely assessing a site and providing a solution to installing and delivering",
    title3: "Laurent provides hassle-free, budget-friendly services with high quality and durability.",
    // description: "Hassle-Free Service",
    // description2: "Budget Friendly",
    // description3: "High quality",
    // description4: "Durability",
  },
  {
    id: 5,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852-4.png',
    // number: "05",
    title: "Interior & Exterior design",
    // title2:
    // "Our end-to-end competence in power solutions for the Residential industry makes us a preferred provider, from precisely assessing a site and providing a solution to installing and delivering",
    // title3: "Laurent provides:",
    description:
      "Our dedicated and innovative Designers strive to deliver high-quality and sustainable solutions for our Clients across a wide range of industries.",
    description2:
      "Laurent's interior design services include complete makeovers and design overhauls, providing opportunities for customization.",
    description3:
      "Exterior design remodeling services range from installing exterior shutters to full-scale exterior remodels",
    description4:
      "Laurent is one of the best interior design when it comes to cost-effective and time-efficient solutions",
  },
  {
    id: 6,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/mobile/Rectangle 852-5.png',
    // number: "06",
    title: "Bill of Quantities",
    description:
      "Our expert engineers at Laurent for construction prepare Bills of quantities divided into sections that explain the project, materials needed, unit prices, provisional sums, methods of measurement, and potential errors. ",
    description2:
      "Our team allows construction companies to create the estimated cost of the project and allows them to bid on taking the work.  ",
    // description3:
    //   "From a project as simple as installing exterior shutters, to full-scale exterior remodels, our goal is to help you through the exterior design process so that your project turns out perfectly!",
  },
  {
    id: 7,
    img: 'https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/Group 19200.jpg',
    // number: "07",
    title: "Foundation works service",
    title2:
      "As experts in the construction and civil engineering industry, We develop different types of foundations: ",
    description: "crawlspace.",
    description2: "basement.",
    description3: "Slab-on-grade with stem wall.",
    description4: "monolithic slab.",
    description5: "piles.",
    description6: "piers.",
    description7: "Alternative methods. ",
  },

  
  // {
  //   id: 8,
  //   img: img8,
  //   // number: "08",
  //   title: "MEP DESIGN",
  //   description:
  //     "Our Civil Designs service provides accurate and tailored planning for diverse civil engineering projects, ensuring functionality and compliance. Trust our experienced team for reliable solutions in residential, commercial, or infrastructure developmen",
  //   // description2: "",
  //   // description3: "",
  // },
];
export default Data;
