import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataEng from "./DataEngineering";
// import "./../ItemDetails.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export default function ItemDetailsEng() {
  const [itemEng, setItemEng] = useState(null);
  const paramsEng = useParams();
  useEffect(() => {
    const itemitemEngId = parseInt(paramsEng.detailsEngId);
    const selectedItemEng = DataEng.find(
      (itemEng) => itemEng.id === itemitemEngId
    );
    setItemEng(selectedItemEng);
  }, [paramsEng.detailsEngId]);

  if (!itemEng) {
    return <div>Loading...</div>;
  }

  const imageKeys = Object.keys(itemEng).filter((key) =>
    key.startsWith("imgDetails")
  );
  return (
    <>
      <section className="containerItemDetails">
        <div
          className="itemDetailsHeader"
          style={{
            // backgroundImage: `url(${itemEng.backgroundImage})`,
            backgroundImage: `url(${
              window.innerWidth < 768 ? itemEng.imgMobile : itemEng.imgDesktop
            })`,
            // height: "100vh",
            // backgroundColor: "#aaa",
          }}
        >
          <div className="container textServicesDetails">
            <h2>our services</h2>
            {/* <h3>steel structure </h3> */}
            <h3>{itemEng.title}</h3>
          </div>
        </div>
        <div className="container">
          <div className="itemDetails">
            {/* <h2>steel structure </h2> */}
            <h2>{itemEng.title}</h2>
            {itemEng.description &&<p className="descriptionMain">{itemEng.description}</p> }
            {itemEng.description02 &&<p className="descriptionMain">{itemEng.description02}</p> }
            {itemEng.description03 &&<p className="descriptionMain">{itemEng.description03}</p> }
            {itemEng.description04 &&<p className="descriptionMain">{itemEng.description04}</p> }
            <div className="descriptionWorks">
              <div>
                {itemEng.description2 && <p>{itemEng.description2}</p>}
                {itemEng.description3 && <p>{itemEng.description3}</p>}
                {itemEng.description4 && <p>{itemEng.description4}</p>}
              </div>
              <div>
                {/* <p>{itemEng.description5}</p> */}
                {/* <p>{itemEng.description6}</p> */}
                {/* <p>{itemEng.description7}</p> */}
                {itemEng.description5 && <p>{itemEng.description5}</p>}
                {itemEng.description6 && <p>{itemEng.description6}</p>}
                {itemEng.description7 && <p>{itemEng.description7}</p>}
              </div>
            </div>

            <div className="contDetails">
              <div className="imgsButtom">
                {/* <>
                <img
                  className="imgDetails2"
                  src={itemEng.imgDetails1}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails2"
                  src={itemEng.imgDetails2}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails3"
                  src={itemEng.imgDetails3}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails4}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails5}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails6}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails7}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails8}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails9}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails10}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails11}
                  alt="servicesImg"
                />
                <img
                  className="imgDetails4"
                  src={itemEng.imgDetails12}
                  alt="servicesImg"
                />
</> */}

                <ImageGallery
                  // stopPropagation={true}
                  // disableSwipe={false}
                  // disableThumbnailSwipe={false}
                  showIndex={true}
                  showBullets={true}
                  autoPlay={true}
                  items={imageKeys
                    .map((imgKey,index) => {
                      if (itemEng[imgKey]) {
                        return {
                          original: itemEng[imgKey],
                          thumbnail: itemEng[imgKey],
                          key: `image_${index}`,
                        };
                      }
                      return null;
                    })
                    .filter(Boolean)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
