import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Slider from "react-slick";
// import { baseUrl } from "../../../utils/baseUrl";
import Loading from "./../../Loading/Loading";
import "./ProjectsMain.scss";
import { Link } from "react-router-dom";

// import icon from "./../../../assets/projects/Vectorc.svg";
// import icon2 from "./../../../assets/projects/Venctor.svg";
const BASE_URL = "https://dashboard.laurentegypt.com/api";
const LIMIT = 4;

export default function ProjectsMain(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // let limit = 4;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllComments = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/comments?_page=1&_limit=${LIMIT}`
      );
      const total = res.headers["x-total-count"];
      setPageCount(Math.ceil(total / LIMIT));
    } catch (error) {
      console.error("Failed to fetch comments", error);
    }
  };

  useEffect(() => {
    getAllComments();
  }, []);

  useEffect(() => {
    getAllCategories(currentPage);
  }, [currentPage]);

  // const getAllCategories = async (page = 1) => {
  //   try {
  //     let { data } = await axios.get(`${BASE_URL}/portfolio?page=${page}`);
  //     setCategories(data.data.data);
  //     setPageCount(data.data.last_page);
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  const getAllCategories = async (page = 1, serviceId = null) => {
    try {
      let url = serviceId
        ? `${BASE_URL}/service/filter/${serviceId}?page=${page}`
        : `${BASE_URL}/portfolio?page=${page}`;
      let { data } = await axios.get(url);
      setCategories(data.data.data);
      setPageCount(data.data.last_page);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    setCurrentPage(selected);
  };

  const getServices = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/service`);
      if (data && data.status === 200) {
        setServices(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch services", error);
    }
  };
  useEffect(() => {
    getServices();
  }, []);
  const handleCategoryChange = (serviceId) => {
    setCurrentPage(1);
    setSelectedCategory(serviceId);
    getAllCategories(1, serviceId);
  };
  const handleShowAll = () => {
    setCurrentPage(1);
    setSelectedCategory(null);
    getAllCategories(1, null);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    vertical: false,
  };

  return (
    <section className="projects">
      <div className="container">
        <div className="">
          {/* <div className="projectItems"> */}

          <div className="categories-buttons">
            <button
              className={selectedCategory === null ? "active" : ""}
              onClick={handleShowAll}
            >
              All Projects
            </button>
            {services.map((service) => (
              <button
                key={service.id}
                onClick={() => handleCategoryChange(service.id)}
                className={selectedCategory === service.id ? "active" : ""}
              >
                {service.Ptype}
              </button>
            ))}
          </div>

          <div>
            <div className="proSlider">
              {categories.length > 0 ? (
                categories.map((item) => {
                  return (
                    <div key={item.id} className="parentImg my-2">
                      <Link to={`/detailspro/${item.id}`}>
                        <Slider {...settings} autoplayspeed={3000} className="">
                          {item.thumbnails.map((thumb, index) => (
                            <div key={index} className="imgSlide">
                              <img
                                src={thumb.thumbnail}
                                alt="ourProjectsImgSlider"
                                className=""
                              />
                            </div>
                          ))}
                        </Slider>

                        <div className="slidText">
                          <div>
                            <h5>{item.project_name}</h5>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              ) : (
                <Loading />
              )}
            </div>
          </div>

          {/* </div> */}
        </div>
        <div className="proPagination">
          <ReactPaginate
            previousLabel={
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Venctor.svg"
                alt="iconPagination"
              />
            }
            nextLabel={
              <img
                src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/Vectorc.svg"
                alt="iconPagination"
              />
            }
            breakLabel={"..."}
            pageCount={pageCount}
            forcePage={currentPage - 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </section>
  );
}
