import React from "react";
import "./OurClients.scss";
export default function Item(props) {
  return (
    <section className="card">
      <div className="cardContent" key={props.id}>
        <div className="imgMainParent">
          <img className="imgMain" src={props.img} alt="ourClientsImg" />
        </div>
        <div className="imgHover">
          <img className="" src={props.imgHover} alt="ourClientsImg" />
        </div>
      </div>
    </section>
  );
}
