import Slider from "react-slick";
import Loading from "../../Loading/Loading";
import Data from "./Data";
import "./OurServices.scss";
// import { useRef } from "react";
export default function OurServices() {
  // const [sliderValue, setSliderValue] = useState(0);
  // const sliderRef = useRef(null);
  // // const handleSliderChange = (event) => {
  // //   const newValue = parseInt(event.target.value);
  // //   setSliderValue(newValue);
  // // };

  // const handleSliderChange = (event) => {
  //   const newSliderValue = parseInt(event.target.value);
  //   setSliderValue(newSliderValue);

  //   // Calculate the corresponding slide based on the new slider value
  //   const numberOfSlides = Data.length;
  //   const newSlide = Math.floor((newSliderValue / 100) * (numberOfSlides - 1));

  //   // Change the current slide in the slider using the sliderRef
  //   if (sliderRef.current) {
  //     sliderRef.current.slickGoTo(newSlide);
  //   }
  // };

  var settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
  };
  return (
    <div className="ourServic">
      <div className="container">
        <section className="ourServices">
          <div className="ourServHead">
            <h2>our services</h2>

            <h3>
              Laurent provides a wide range of services to meet all your
              construction and engineering needs.
            </h3>
          </div>

          <div className="ourServSlid">
            <div className="my-5 container">
              <Slider
                {...settings}
                autoplayspeed={3000}
                className=""
                // afterChange={(currentSlide) => {
                //   setSliderValue((currentSlide / (Data.length - 1)) * 100);
                // }}
                // afterChange={(currentSlide) => {
                //   setSliderValue((currentSlide / (Data.length - 1)) * 100);
                // }}
                // ref={(slider) => (sliderRef.current = slider)}
              >
                {Data.length > 0 ? (
                  Data.map((item,idx) => {
                    return (
                      <div key={item.id} className="parentImg slide">
                        <h5 className="mobile-title">{item.title}</h5>
                        <div className="imgSlide">
                          <img
                            src={item.img}
                            alt="ourServicesImgSlider"
                            className=""
                          />
                        </div>
                        <div className="slidText">
                          <div className={item.class}>
                            {/* <h5>{item.number}</h5> */}
                            <h5 className="desktop-title">{item.title}</h5>
                            {/* <h6>{item.title2}</h6> */}
                            {/* <h6>{item.title3}</h6> */}
                            {/* {item.title2 && <h6>{item.title2}</h6>}
                              {item.title3 && <h6>{item.title3}</h6>}
                              <p>{item.description}</p>
                              <p>{item.description2}</p>
                              <p>{item.description3}</p>
                              <p>{item.description4}</p>
                              <p>{item.description5}</p>
                              <p>{item.description6}</p>
                              <p>{item.description7}</p> */}
                            {[item.title2, item.title3].map(
                              (title, index) =>
                                title && <h6 key={`title_${index}`}>{title}</h6>
                            )}

                            {[
                              item.description,
                              item.description2,
                              item.description3,
                              item.description4,
                              item.description5,
                              item.description6,
                              item.description7,
                            ].map(
                              (desc, index) =>
                                desc && (
                                  <p key={`description_${index}_${idx}`}>{desc}</p>
                                )
                            )}

                            {/* <ul>
                                <li>
                                </li>
                                <li>
                                  <p>{item.description2}</p>
                                </li>
                                <li>
                                  <p>{item.description3}</p>
                                </li>
                              </ul> */}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Loading />
                )}
              </Slider>
              {/* <div className="progressBars_container">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={sliderValue}
                  onChange={handleSliderChange}
                  className="progressBars"
                />
                <div
                  className="progress-bar"
                  style={{ width: `${sliderValue}%` }}
                ></div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
