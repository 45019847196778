import img1 from "./../../../assets/about/GRAY/SR.svg";
import imgHover1 from "./../../../assets/about/COLORS/SR.svg";

import img2 from "./../../../assets/about/GRAY/chukss.svg";
import imgHover2 from "./../../../assets/about/COLORS/chukss.svg";

// import img3 from "./../../../assets/about/GRAY/JOX.svg";
// import imgHover3 from "./../../../assets/about/COLORS/image 3.svg";

// import img4 from "./../../../assets/about/GRAY/andalusia 1.svg";
// import imgHover4 from "./../../../assets/about/COLORS/andalusia 4.png";

import img5 from "./../../../assets/about/GRAY/SAFARI.svg";
import imgHover5 from "./../../../assets/about/COLORS/SAFARI.svg";

import img6 from "./../../../assets/about/GRAY/hospa.svg";
import imgHover6 from "./../../../assets/about/COLORS/hospa.svg";

import img7 from "./../../../assets/about/GRAY/SAVILLS.svg";
import imgHover7 from "./../../../assets/about/COLORS/SAVILLS.svg";

const Data = [
  {
    id: 1,
    img: img1,
    imgHover: imgHover1,
  },
  {
    id: 2,
    img: img2,
    imgHover: imgHover2,
  },
  {
    id: 3,
    img: "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/JOX.svg",
    imgHover:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/image 3.svg",
  },
  {
    id: 4,
    img: "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/andalusia 1.svg",
    imgHover:
      "https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/andalusia 4.png",
  },
  {
    id: 5,
    img: img5,
    imgHover: imgHover5,
  },
  {
    id: 6,
    img: img6,
    imgHover: imgHover6,
  },
  {
    id: 7,
    img: img7,
    imgHover: imgHover7,
  },
];
export default Data;
