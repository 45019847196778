import React, { useEffect } from "react";
import ServicesHead from "./ServicesHead/ServicesHead";
import ServicesMian from "./ServicesMian/ServicesMian";
import { Helmet } from "react-helmet";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Services" />
      </Helmet>
      <ServicesHead />
      <ServicesMian />
    </>
  );
}
