import React, { useEffect } from "react";
import "./ProjectsFooter.scss";
// import img1 from "./../../../assets/projects/projectsFooter/Ellipse 27.png";
// import img2 from "./../../../assets/projects/projectsFooter/Ellipse 28.png";
// import img3 from "./../../../assets/projects/projectsFooter/Ellipse 29.png";

export default function ProjectsFooter() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <hr className="hrPro" />
      <section className="projectsFooter">
        <div className="container proFoot">
          <div>
            <h2>CHECK OUR</h2>
            <h3>LATEST PROJECTS</h3>
          </div>
          <img className="img1" src='https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/projectsFooter/Ellipse 27.png' alt="projectsFooterImg" />
          <img className="img2" src='https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/projectsFooter/Ellipse 28.png' alt="projectsFooterImg" />
          <img className="img3" src='https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/projects/projectsFooter/Ellipse 29.png' alt="projectsFooterImg" />
        </div>
      </section>
    </>
  );
}
