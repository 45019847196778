import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import "./Contact.scss";
// import { Container } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { useNavigate } from "react-router-dom";
// import jwtDecode from "jwt-decode";
// import icon1 from "./../../assets/contact/Vector.svg";
// import icon2 from "./../../assets/contact/Vector2.svg";
// import icon3 from "./../../assets/contact/Vector3.svg";
// import iconInput from "./../../assets/Grou.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  // const [form] = Form.useForm();

  const notify = (msg, type) => {
    toast[type](msg);
  };
  // let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  // const [userData, setUserData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // async function handleLogin(values) {
  //   // useEffect(() => {
  //   //   if (localStorage.getItem("userToken")) {
  //   //     saveUser();
  //   //   }
  //   // }, []);

  //   // function saveUser() {
  //   //   let encodedToken = localStorage.getItem("userToken");
  //   //   let decoded = jwtDecode(encodedToken);
  //   //   setUserData(decoded);
  //   // }

  //   // console.log("1111", values);
  //   setIsLoading(true);
  //   setErrMassage(null);

  //   let { data } = await axios
  //     //dashboard.laurentegypt.com
  //     .post("https://dashboard.laurentegypt.com/api/contact", values)
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //       notify(err.response.data.message, "error");
  //       setErrMassage(err.response.data.message);
  //     });
  //   console.log(data);
  //   if (data.message === "success") {
  //     setIsLoading(false);
  //     // localStorage.setItem("userToken", data.token);
  //     // saveUser();
  //     notify("success", "success");
  //     // navigate("/contact");
  //   }
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  const initialValues = {
    full_name: "",
    email: "",
    kind_property: "",
    kind_service: "",
    messages: "",
  };

  async function handleLogin(values) {
    setIsLoading(true);
    setErrMessage(null);

    // const initialValues = {
    //   fullName: "",
    //   email: "",
    //   kind_property: values.kind_property || "",
    //   kind_service: "",
    //   full_name: "",
    // };

    try {
      const response = await axios.post(
        "https://dashboard.laurentegypt.com/api/contact",
        {
          full_name: values.full_name,
          email: values.email,
          kind_property: values.kind_property,
          kind_service: values.kind_service,
          messages: values.messages,
        }
      );

      const data = response.data;

      console.log(data.message);

      if (data.message === "Contact has been added successfully ") {
        // setIsLoading(false);
        formik.resetForm();
        notify("Success", "success");
      } else {
        notify("An error occurred", "error");
      }
    } catch (err) {
      // console.error(err);
      // setIsLoading(false);
      notify(err.response.data.message, "error");
      // setErrMessage(err.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
      // notify("Success", "success");
    }
  }

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full name is required"),
    kind_property: Yup.string().required("Kind of property  is required"),
    kind_service: Yup.string().required("Kind of services is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    messages: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    // onSubmit: handleLogin,
    onSubmit: (values) => handleLogin(values),
  });

  // let mySchema = Yup.object({
  //   email: Yup.string().email("Invalid email").required("Required"),
  // });

  // let formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema: mySchema,
  //   onSubmit: (values) => handleLogin(values),
  // });

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>

      <section className="contactUs">
        <Container>
          <div className="contContent">
            <section className="headContact">
              <h1>Contact Us</h1>
              <p>Contact us now and get a free Quotation.</p>
            </section>
            <div className="contact">
              <div className="contactLeft">
                <h4>Contact Info :</h4>
                <div className="contactInfo">
                  <img
                    src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Vector.svg"
                    alt="iconPhone"
                  />
                  <p>
                    <a href="tel:(002) 01119427778">(002) 01119427778</a>
                    {/* <span> </span> */}
                  </p>
                </div>
                <div className="contactInfo">
                  <img
                    src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Vector.svg"
                    alt="iconPhone"
                  />
                  <p>
                    <a href="tel:(002) 03-4270316">(002) 03-4270316</a>
                    {/* <span> </span> */}
                  </p>
                </div>
                <div className="contactInfo">
                  <img
                    src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Vector2.svg"
                    alt="iconEmail"
                  />
                  <p>
                    <a href="mailto:info@laurentegypt.com">
                      info@laurentegypt.com
                    </a>
                    {/* <span></span> */}
                  </p>
                </div>
                <div className="contactInfo">
                  <img
                    src="https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/contact/Vector3.svg"
                    alt="iconMp"
                  />
                  <p>
                    Sporting Branch, 10 Mounir St, Fourth floor, in front of
                    Sporting Club Al-Houria St.
                    {/* <span>
                    </span> */}
                  </p>
                </div>
                <div className="map">
                  <iframe
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13976892.675274367!2d48.35111371714248!3d31.211659999999988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c5f5be907c0f%3A0x58b9f3cb208fafe0!2sLaurent%20construction%20company!5e0!3m2!1sar!2seg!4v1691880008116!5m2!1sar!2seg"

                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d213.2702802373182!2d29.934631141656016!3d31.21173841168201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f5c5f5be907c0f%3A0x58b9f3cb208fafe0!2sLaurent%20construction%20company!5e0!3m2!1sar!2seg!4v1692294005916!5m2!1sar!2seg"
                    // allowfullscreen=""
                    title="خريطة الموقع"
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="contactRight">
                {/* <h4>Let's talk with Us</h4> */}
                {/* <p>
                  Have a project in mind that you think we’d be a great fit for
                  it? We’d love to know what you’re thinking
                </p> */}
                <div>
                  {/* {errMessage ? (
                    <div className="alert alert-danger mt-5">{errMessage}</div>
                  ) : (
                    ""
                  )} */}
                  {errMessage && (
                    <div className="alert alert-danger mt-5">{errMessage}</div>
                  )}
                  <Form className="formContact" onSubmit={formik.handleSubmit}>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Group className="mb-3" controlId="formBasicFullName">
                      <Form.Control
                        type="text"
                        placeholder="Input your full name in here"
                        name="full_name"
                        value={formik.values.full_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.full_name && formik.touched.full_name && (
                        <div className="alert alert-danger">
                          {formik.errors.full_name}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Input your email address in here"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="alert alert-danger">
                          {formik.errors.email}
                        </div>
                      )}
                      {/* {formik.errors.email && formik.touched.email ? (
                        <div className="alert alert-danger">
                          {formik.errors.email}{" "}
                        </div>
                      ) : (
                        ""
                      )} */}
                      {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text> */}
                    </Form.Group>

                    {/* <Form.Label>Kind of property </Form.Label> */}

                    {/* <Form.Group
                      className="mb-3 iconInInput"
                      controlId="formBasicCommercial "
                    >
                      <Form.Control type="text" placeholder="commercial " />
                      <NavDropdown title="" id="basic-nav-dropdown">
                        <NavDropdown.Item to="/action/3.1">
                          Construction
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.2">
                          Engineering Services
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.3">
                          investment
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.4">
                          Store
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.5">
                          Agency
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Form.Group>

                    <Form.Label>Kind of services</Form.Label>
                    <Form.Group
                      className="mb-3 iconInInput"
                      controlId="formBasicFullName"
                    >
                      <Form.Control
                        type="text"
                        placeholder="steel structure "
                      />
                      <NavDropdown title="" id="basic-nav-dropdown">
                        <NavDropdown.Item to="/action/3.1">
                          Construction
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.2">
                          Engineering Services
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.3">
                          investment
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.4">
                          Store
                        </NavDropdown.Item>
                        <NavDropdown.Item to="/action/3.5">
                          Agency
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Form.Group>  */}

                    <Form.Group className="mb-3">
                      <Form.Label>Kind of property</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        size="lg"
                        name="kind_property"
                        value={formik.values.kind_property}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option>Open this select menu</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Residential">Residential</option>

                        {/* <option>commercial</option>
                        <option>Large select2</option>
                        <option>Large select3</option>
                        <option>Large select4</option> */}
                      </Form.Select>
                      {formik.errors.kind_property &&
                        formik.touched.kind_property && (
                          <div className="alert alert-danger">
                            {formik.errors.kind_property}
                          </div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Kind of services</Form.Label>
                      <div className="mb-3 ">
                        <Form.Select
                          aria-label="Default select example"
                          size="lg"
                          name="kind_service"
                          value={formik.values.kind_service}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option>Open this select menu</option>
                          <option value="Mechanical Engineering">
                            Mechanical Engineering
                          </option>
                          <option value="Electrical Engineering">
                            Electrical Engineering
                          </option>
                          <option value="Plumbing Engineering">
                            Plumbing Engineering{" "}
                          </option>
                          <option value="Steel Structure">
                            Steel Structure{" "}
                          </option>
                          <option value="Interior & Exterior Desgin">
                            Interior & Exterior Desgin{" "}
                          </option>
                          <option value="Bill of Quantities">
                            Bill of Quantities{" "}
                          </option>
                          <option value="Commercial Construction">
                            Commercial Construction{" "}
                          </option>
                          <option value="Residential Construction">
                            Residential Construction{" "}
                          </option>
                          <option value="Foundation Services">
                            Foundation Services
                          </option>
                          {/* <option>steel structure</option>
                          <option>Large select2</option>
                          <option>Large select3</option>
                          <option>Large select4</option> */}
                        </Form.Select>
                      </div>
                      {formik.errors.kind_service &&
                        formik.touched.kind_service && (
                          <div className="alert alert-danger">
                            {formik.errors.kind_service}
                          </div>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCountry">
                      <Form.Label>Messages</Form.Label>
                      {/* <Form.Control type="text" placeholder="Country" /> */}
                      <textarea
                        className="form-control"
                        // name=""
                        id=""
                        // cols="30"
                        // rows="5"
                        placeholder="Write your messages in here"
                        name="messages"
                        value={formik.values.messages}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      ></textarea>
                      {formik.errors.messages && formik.touched.messages && (
                        <div className="alert alert-danger">
                          {formik.errors.messages}
                        </div>
                      )}
                    </Form.Group>

                    <div className="btnSubmit">
                      <Button
                        variant=""
                        type="submit"
                        className=""
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="2x"
                            color="green"
                          />
                        ) : (
                          "Send Message"
                        )}

                        {/* Send Messages */}
                        {/* {isLoading === true ? (
                          <i className="fas fa-spinner fa-spin"></i>
                        ) : (
                          "Send Messages"
                        )} */}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
