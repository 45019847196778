// import './App.scss';
// import Footer from "./Components/Footer/Footer";
import { ToastContainer } from 'react-toastify';
// import { Toaster } from 'react-hot-toast';
import { createHashRouter, RouterProvider } from "react-router-dom";
import MainLayout from "./Components/Layouts/MainLayout";
import Contact from "./Components/Contact/Contact";
import errImg from "./assets/home/error.svg";
import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import ItemDetails from "./Components/Services/ServicesMian/MepServices/ItemDetails";
// import Gallery from './Components/Gallery/Gallery';
import ProjectDetails from "./Components/Projects/ProjectDetails/ProjectDetails";
import ItemDetailsEng from "./Components/Services/ServicesMian/EngineeringServices/ItemDetailsEng";
import Projects from './Components/Projects/Projects';
// import { useEffect } from "react";

function App() {
  // const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location])

  let routes = createHashRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { index: true, element: <Home /> },
        // { index: true, element: <Home /> },
        { path: "about", element: <About /> },
        { path: "contact", element: <Contact /> },
        { path: "projects", element: <Projects /> },
        { path: "services", element: <Services /> },
        { path: "/details/:detailsId", element: <ItemDetails /> },
        { path: "/detailseng/:detailsEngId", element: <ItemDetailsEng /> },
        // { path: "gallery", element: <Gallery /> },
        { path: "/detailspro/:id", element: <ProjectDetails /> },
        // { path: "product-details/:id", element: <ProductDetails /> },
        // { path: "contact", element: <Contact /> },
        // { path: "product-details/:id", element: <ProductDetails /> },
        // { path: "categorie-details/:id", element: <CategorietDetails /> },
        {
          path: "*",
          element: (
            <div className="text-center py-3">
              <img src={errImg} alt="" />
            </div>
          ),
        },

        // {
        //   path: "brands",
        //   element: (
        //     <ProtectedRoutes>
        //       <Brands />
        //     </ProtectedRoutes>
        //   ),
        // },
      ],
    },
  ]);

  return (
    <>
      {/* <Home />
      <Footer /> */}
      {/* <Toaster /> */}
      <ToastContainer theme="colored" />
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
