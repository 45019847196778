import React, { useEffect } from "react";
// import icon from "./../../../assets/Vec22tor.svg";
import "./ProjectsHead.scss";
export default function ProjectsHead() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="projectsHead">
      <div className="container">
        <h1>OUR PROJECTS</h1>
        <h6>
          We have an extensive experience working with prominent clients locally
          and internationally on various projects and initiatives.
        </h6>
      </div>
    </section>
  );
}
