import React,{useEffect} from "react";
import AboutHead from "./AboutHead/AboutHead";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
// import OurCore from "./OurCore/OurCore";
import OurClients from "./OurClients/OurClients";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";
export default function About() {
  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About" />
      </Helmet>
      <AboutHead />
      <WhoWeAre />
      {/* <OurCore /> */}
      <OurClients />
    </>
  );
}
