import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
// import {faEnvelope} from "@fortawesome/fontawesome-free"
import {
  // faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./Footer.scss";
// import logo from "./../../assets/home/footer/Layer_01.svg";
// import logo2 from "./../../assets/Group.png";
// import icon1 from "./../../assets/Facebook Icon.svg";
// import icon0 from "./../../assets/home/footer/whatsapp-svgrepo-com 1.svg";
// import icon1 from "./../../assets/Facebook -Icon.svg";
// import icon2 from "./../../assets/Linkedin- Icon.svg";
// import icon3 from "./../../assets/Twitter -Icon.svg";
// import icon4 from "./../../assets/Twitter--Icon.png";
// import icon1 from "./../../assets/Facebook -Icon.svg";
// import icon2 from "./../../assets/Linkedin- Icon.svg";
// import icon3 from "./../../assets/Twitter -Icon.svg";
// import icon4 from "./../../assets/Twitter--Icon.png";

export default function Footer() {
  const [isContactPage, setIsContactPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsContactPage(location.pathname === "/contact");
  }, [location]);

  return (
    <section className="container1">
      <footer>
        {/* <div className="footerHead">
          <h4>get Quotes</h4>
          <p>
            I am satisfied with the training given by XYZ Pvt. Ltd <br /> on Web
            Designing. During
          </p>
          <div className="getStarted">
            <NavLink to="/contact">get started</NavLink>
          </div>
          <hr />
        </div> */}

        {!isContactPage && (
          <div className="footerHead">
            <h4>get Quotes</h4>
            <p>Contact us now and get a free quotation.</p>
            <div className="getStarted">
              <NavLink to="/contact">get started</NavLink>
            </div>
            <hr />
          </div>
        )}

        <div className="container">
          <div className="footer-start">
            <div className="footContent">
              <div className="footLeft">
                <img
                  className="logoFoot"
                  src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/logo fooltf.svg"
                  alt="logo"
                />
                <p>
                  Laurent for Construction and Engineering offers tailored,
                  full-service construction and industrial engineering solutions
                  to meet your specific needs.
                </p>
              </div>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
              </ul>
              {/* <div className="footContCenter">

            </div> */}
              <ul>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/contact">Get a Quote</Link>
                </li>
              </ul>

              <div className="footerRight">
                <a
                  className="iconWhats"
                  href="https://api.whatsapp.com/send/?phone=201119427778"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://usc1.contabostorage.com/0827d2f37d5b40f4a6e88ba1200e8cbe:laurent/whatsapp-svgrepo-com 1.svg"
                    alt="whatsappIcon"
                  />
                </a>
                <p>Follow Us</p>
                <div className="footIcon">
                  {/* <a
                    href="https://www.facebook.com/Laurent.egypt"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  {/* <img src={icon1} alt="" /> */}
                  {/* <i className="fa-brands fa-facebook-f"></i> */}

                  {/* <FontAwesomeIcon icon={faFacebookF} /> */}

                  {/* <FontAwesomeIcon icon="fa-brands fa-facebook" /> */}
                  {/* <FontAwesomeIcon icon={faCoffee} /> */}
                  {/* </a> */}
                  <a
                    href="https://www.linkedin.com/company/laurent-company/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <img src={icon2} alt="" /> */}
                    {/* <i className="fa-brands fa-linkedin-in"></i> */}
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  {/* <a href="https://www.facebook.com/" target="_blank"  rel="noopener noreferrer">
                    <img src={icon3} alt="" />
                    <i className="fa-brands fa-twitter"></i>
                  </a> */}
                  <a
                    href="mailto:info@laurentegypt.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <img className="iconMess1" src={icon4} alt="" /> */}
                    {/* <i className="fa-solid fa-envelope"></i> */}
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>

                  <a
                    href="https://www.instagram.com/laurent.egypt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRight">
          <hr />
          <p>© 2023 powered by Laurent Group. All Rights Reserved. </p>
        </div>
      </footer>
    </section>
  );
}
