// import img1 from "./../../../assets/about/ourClients/image 19.svg";
// import img2 from "./../../../assets/about/ourClients/image 20.svg";
// import img3 from "./../../../assets/about/ourClients/INVASSO-GRAY.svg";

// import imgHover1 from "./../../../assets/about/ourClients/image 192.svg";
// import imgHover2 from "./../../../assets/about/ourClients/image 202.svg";
// import imgHover3 from "./../../../assets/about/ourClients/INVASSO-.svg";

const DataEngineering = [
  {
    id: 1,
    img: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/image 19.svg',
    imgHover: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/image 192.svg',
  },
  {
    id: 2,
    img: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/image 20.svg',
    imgHover: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/image 202.svg',
  },
  {
    id: 3,
    img: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/INVASSO-GRAY.svg',
    imgHover: 'https://eu2.contabostorage.com/6faa01181769496ebdd861e85f94a266:laurentegypt/public website/laurent/aboutourClients/INVASSO-.svg',
  },
  // {
  //   id: 4,
  //   img: img4,
  // },
];
export default DataEngineering;
