import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./ProjectDetails.scss";
// import img1 from "./../../../assets/projects/5 1.png";
// import img2 from "./../../../assets/projects/Cinema Room-Modelww 1.png";
// import Featrues from "./Featrues/Featrues";
import ProjectsFooter from "../ProjectsFooter/ProjectsFooter";
import axios from "axios";
import Slider from "react-slick";
import Loading from "./../../Loading/Loading";
// const BASE_URL = "https://dashboard.laurentegypt.com/api";

export default function ProjectDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const itemId = parseInt(params.detailsId, 10);
  //   const selectedItem = Data.find((item) => item.id === itemId);
  //   setItem(selectedItem);
  // }, [params.detailsId]);

  // if (!item) {
  //   return <div>Loading...</div>;
  // }

  // const imageKeys = Object.keys(item).filter((key) =>
  //   key.startsWith("imgDetails")
  // );

  let { id } = useParams();

  const [categories, setCategories] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);

  const baseUrl = "https://dashboard.laurentegypt.com/api";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllCategories = useCallback(async () => {
    try {
      let { data } = await axios.get(`${baseUrl}/portfolio/${id}`);
      setCategories(data.data.portfolio__plan);
      setProjectDetails(data.data);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      // { breakpoint: 576, settings: { slidesToShow: 1 } },
      // { breakpoint: 815, settings: { slidesToShow: 2 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 1400, settings: { slidesToShow: 2 } },
    ],
  };

  return (
    <>
      <div className="projectDetailsHeader">
        {projectDetails && (
          // <video controls>
          //   {/* <source src={projectDetails.video} type="video/mp4" /> */}
          //   <source
          //     src="https://www.youtube.com/watch?v=zUSGgBXu0x0"
          //     type="video/mp4"
          //   />
          //   Your browser does not support the video tag.
          // </video>
          // "https://www.youtube.com/embed/zUSGgBXu0x0?si=LoajT8Gk8yGaB3Vh"
          <iframe
            className="video"
            // width="100%"
            // height="315"
            src={projectDetails.video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="true"
          ></iframe>

          // <iframe
          //   width="100%"
          //   height="315"
          //   src={projectDetails.video}
          //   title="Project Video"
          //   frameBorder="0"
          //   allowFullScreen
          // ></iframe>
        )}
      </div>
      <section className="ProjectDetails">
        <div className="ProjectDetMain">
          <div className="container">
            {projectDetails && (
              <>
                <h2>{projectDetails.project_name}</h2>
                {/*
                <p>{projectDetails.description}</p> */}

                {/* <div className="projectDescription">{projectDetails.description}</div> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: projectDetails.description,
                  }}
                />
              </>
            )}
            <div className="imgs">
              {projectDetails &&
                projectDetails.portfolio__image.map((image, index) => (
                  <img
                    key={index}
                    src={image.portfolio_image}
                    alt="projectDetailImg"
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="container">
          <section className="featrues">
            <h2>plans</h2>

            {projectDetails && (
              <>
                {/* <p>{projectDetails.plans_description}</p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: projectDetails.plans_description,
                  }}
                />
              </>
            )}
            <div className="featruesSlider">
              <div>
                <Slider {...settings} autoplayspeed={3000} className="">
                  {categories.length > 0 ? (
                    categories.map((item) => {
                      return (
                        <div key={item.id} className="parentImg1">
                          <div className="imgSlide">
                            <img
                              src={item.portfolio_plan}
                              alt="projectDetailImgSlider"
                              className=""
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </Slider>
              </div>

              <div className="slidBut">
                <Slider {...settings} autoplayspeed={3000} className="">
                  {categories.length > 0 ? (
                    categories.map((item) => {
                      return (
                        <div key={item._id} className="parentImg1">
                          <div className="imgSlide">
                            <img
                              src={item.portfolio_plan}
                              alt="projectDetailImgSlider"
                              className=""
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </Slider>
              </div>
            </div>
          </section>
        </div>
        {/* <Featrues /> */}
        <ProjectsFooter />
      </section>
    </>
  );
}
